import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../layout';
import config from '../../data/SiteConfig';
import ErrorPage from '../components/System/ErrorPage';

const NotFound = () => (
  <Layout hideFooter>
    <div className="landing-container">
      <Helmet title={config.siteTitle} />
      <ErrorPage errorType={404} />
    </div>
  </Layout>
);

export default NotFound;
